<template>
  <div class="core_c">
    <inside_banner
        mode="speedydelivery_agency"
        :owner_perm="false"
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
        @continue_clicked="do_nothing"
    ></basic_page_infrastructure>

    <section>
      <h1 class="flex_centered_start_column bg_primary_purple text_whitesmoke">
        Register the Tiny Magic Hat Assistant
      </h1>
      <div class="flex_centered_start_column">
        <p class=" margin_tb_30 font_weight_800 margin_lr_20 wid_60">The Assistant integrates your WellSky/ClearCare
          data with Tiny Magic Hat. Unless you have
          changed user accounts or passwords on WellSky/ClearCare or you have updated your Tiny Magic Hat password you
          should not make any adjustments here.</p>
        <p class="font_weight_800 margin_lr_20 wid_60">If you have any questions or are having problems with the
          Assistant, please call Tiny Magic Hat support immediately.</p>
      </div>

      <div class="flex_centered_row">
        <div class="flex_centered_column">
          <input_with_label
              ref="tmh_user_name_collection"
              class="login_input"
              label="Tiny Magic Hat User Name"
              @emit_state="do_nothing"
          ></input_with_label>

          <input_with_label
              ref="tmh_user_pw_collection"
              class="login_input2"
              label="Tiny Magic Hat Password"
              @emit_state="do_nothing"
          ></input_with_label>

          <input_with_label
              ref="wellsky_name_collection"
              class="login_input2"
              label="WellSky User Name"
              @emit_state="do_nothing"
          ></input_with_label>

          <input_with_label
              ref="wellsky_pw_collection"
              class="login_input2"
              label="WellSky Password"
              @emit_state="do_nothing"
          ></input_with_label>

          <dropdown_datalist
              class="login_input2 "
              ref="sd_state"
              label="Upload Day"
              :data_list=week_days
              @value-selected="do_nothing"
          ></dropdown_datalist>

          <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state=true
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="Submit"
            text_size="14px"
            :bold="true"
            event_name="login_button_clicked"
            @login_button_clicked="print_enc_obj"
            tabindex="0"
            v-on:keyup.enter="print_enc_obj"
        ></basic_text_button>

        </div>

      </div>


    </section>

  </div>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import store from "@/store";
import {
  get_uuid, num_is_between_inclusive, round, encryptText,
  colors, desktop_size, scroll_to_top,
  good_token_now, receive_over_socket, be_main_address, prime_socket, decryptText, encryptObject, decryptObject
} from "@/library";
import {Database} from "@/client_db";
import input_with_label from "@/components/parts/input_with_label.vue";
import drop_down_select from "@/components/parts/drop_down_select.vue";
import dropdown_datalist from "@/components/parts/dropdown_datalist.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import axios from "axios";

export default {
  name: "assistant_registration",
  async mounted() {
    scroll_to_top()
    this.id_ = get_uuid()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()

    try {
      await this.$nextTick(function () {

        if (false) {
          // let box = document.getElementById(this.id)
          // box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (false) {
          // let box = document.getElementById(this.id)
          // box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [
    "button_text",
  ],
  emits: ['name_of_event'],
  components: {
    basic_text_button,
    dropdown_datalist,
    drop_down_select,
    input_with_label,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    inside_banner,
    basic_page_infrastructure
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      max_mobile_size: 700,
      button_height_: "80px",
      button_width_: "100px",
      week_days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      creds:
          {
            'tmh_pw': '',
            'cc_pw': '',
            'tmh_user_name': '',
            'cc_user_name': '',
          },
      data2: null
    }
  },
  methods: {
    // async print_enc_obj(){
    //
    //   const password = 'securepassword';
    //   let plain = "This is the big big seccret...."
    //   let plain_obj = {
    //     "tmh_pw": "tunafish",
    //     "tmh_user_name": "tommythecat",
    //     "cc_pw": "piehole",
    //     "cc_user_name": "tommy@hecat.com",
    //   }
    //
    //   // let json_object = JSON.stringify(plain_obj)
    //   //
    //   console.log("Here is the plain object.")
    //   console.log(plain_obj)
    //   let res = await  encryptObject(plain_obj, password)
    //   console.log("Here is the encrypted object")
    //   console.log(res)
    //   console.log("Here is the decrypted object")
    //   let res3 = 'U2FsdGVkX18EfJLgiL6BncBqGIY0fQoKfKPSjJnPDufty/J6e5vxfP2ws7coAmmUSvExcEKefmTzKiOyak0JrW+gdXE/dPDVYX/0hGRqz9qGYzVNoVXcvyB5CIgJhoQv8GZbH2r77xCH95NuWbvDv2EioO86v63v08kolvzGhck='
    //   let res2 = await decryptObject(res3, password)
    //   console.log(res2)
    //
    // },

    do_nothing() {
      console.log("do nothing")
    },

    async send_credentials() {
      let target_url = be_main_address('ft') + 'save_creds'

      let the_agency = store.getters.org_context


      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        creds: this.credentials,
        agency: the_agency,
      }

      await axios.post(target_url, data1)
          .then((response) => {

                if (response.data.error === false) {
                  console.log(response.data)
                  return response.data.data

                } else {
                  console.log("Something went wrong with the Assistant Registration")
                  console.log(response.data)
                }
              }
          )
    },


    open_spinner() {
      store.commit('set_spinner_state', true)
    },
    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    emit_state() {
      this.$emit(this.event_name, this.data1, this.data2)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Pull something from IndexedDB?.")

    },

    current_size_is_mobile(new_w) {
      if (new_w > this.max_mobile_size) {
        return false
      } else {
        return true
      }
    },
    run_window_width_adjustments(new_w) {

      if (new_w > 100) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"

      }
      if (new_w >= 280) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "220px"
      }
      if (new_w >= 400) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        this.mobile_mode_x = this.current_size_is_mobile(new_w)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    credentials(){
      return this.creds
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    good_token() {
      return good_token_now()
    },

    mobile_mode() {
      return this.mobile_mode_x
    },

    button_height() {
      return this.button_height_
    },
    button_width() {
      return this.button_width_
    },
  },
  watch: {
    window_width(new_w) {
      this.run_window_width_adjustments(new_w)
    },
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }

  .login_input {
    margin-top: 50px;
    width: 85vw;
    max-width: 400px;
  }

  .login_input2 {
    margin-top: 20px;
    width: 85vw;
    max-width: 400px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>