<template>
  <section class="pda_profile flex_centered_start_column core_c">

    <inside_banner
        mode="profileagency_home"
        :owner_perm="admins_perm"
        @agency_info_clicked="profile_up_hamburger_down"
        @invite_clicked="associate_up_hamburger_down"
        @promote_clicked="promote_up_hamburger_down"
        @get_assistant="go_to_assistant_registration_page"
    >
    </inside_banner>

    <spinner
        :spinner_up="spinner_up"
        @network_problem="close_spinner_wrapper"
    ></spinner>


    <!--  Pop up associations data form-->
    <universal_pop_up_slot
        :show_popup="associate_form_up"
        @universal_popup_bg_clicked="toggle_associate_form"
        :backer_color="colors.dark_glass"
    >

      <div class="">
        <div class="bg_orange_light flex_centered_column b_rad_25px assoc_popup_main_box ">
          <div
              class="font_raleway text_align_center margin_t_20 text_primary_purple text_30 font_weight_800">
            Meet
          </div>
          <div class="assoc_popup_content_column">


            <div class="flex_centered_row text_align_center">
              <label_value_non_editable
                  class="wid_75"
                  label="Create Invitation"
                  :value=current_assoc_code_invitation
                  :bg_color=colors.new_orange_main_light
                  :label_color=colors.black
                  :value_color=colors.black
                  :value_bg_color=colors.white
                  value_font_size="22px"
                  label_font_size="20px"
                  value_width="150px"
              ></label_value_non_editable>
            </div>

            <div class="flex_centered_row margin_b_20">
              <basic_text_button
                  :button_state="true"
                  :button_color="colors.new_primary_purple"
                  button_color_not_ready="gray"
                  button_height="75px"
                  button_width="75px"
                  border_rad_pct="50%"
                  text="Create"
                  :text_color="colors.white"
                  text_size="15px"
                  :bold="true"
                  event_name="send_assoc_code_clicked"
                  @send_assoc_code_clicked="generate_assoc_code"
              ></basic_text_button>
            </div>
          </div>
        </div>
      </div>


    </universal_pop_up_slot>


    <!--    Pop up promotions form-->
    <universal_pop_up_slot
        :show_popup="promote_form_up"
        @universal_popup_bg_clicked="toggle_promote_form"
        :backer_color="colors.dark_glass"
    >

      <div class="promotes_popup_sizing">
        <div class="bg_orange_light flex_centered_column b_rad_25px">
          <div
              class="font_raleway text_align_center margin_tb_10 margin_t_20 text_primary_purple text_25 font_weight_800">
            Promote an Associate
          </div>
          <div class="flex_centered_row">
            <dual_select_and_launch
                class="margin_b_20 promo_selector"
                label=""
                ref="promotion_widgit"
                :ready_state="true"
                :label_text_color_ready="colors.black"
                label_text_ready="Promote User"
                label_font_size_ready="18px"
                :hover_color="colors.new_gold"

                selection_title1="Select User"
                selection_title2="Select New Rank"
                button_text="Promote"
                :values1="promotees_listing"
                :values2="['caregiver','admin0','admin1']"
                event_name="catch_promotee_and_rank"
                @catch_promotee_and_rank="promote_associate"
            >
            </dual_select_and_launch>
          </div>
        </div>
      </div>


    </universal_pop_up_slot>


    <image_upload_widgit
        ref="upload_widgit"
        class=" margin_t_20"
        :image_value="logo_image"
        image_width="260"
        image_height="145"
        frame_text="Click to upload your agency logo"
        event_name="save_pic"
        :owners_perm="owners_perm"
        @save_pic="upload_logo_image"
    ></image_upload_widgit>

    <div class="font_raleway margin_t_10 text_eli_gray">{{ my_rank }}</div>

    <div class="spacer flex_centered_column  margin_auto">
      <div v-if="my_services.length > 0">
        <div class="margin_auto margin_tb_10   padding_t_10 bg_whitesmoke flex_centered_column services_box">
          <h3 class=" text_black ">Services</h3>
          <div class="flex_wrap service_cards">
            <div @click="get_new_route('service', item)" v-for="item in my_services" :key="item.message">
              <div class="flex_centered_column service_card">
                <img v-if="item==='freetime'" class="service_logo" src="@/assets/freetime_sunset.svg"
                     alt="Service Logo">
                <img v-if="item==='spyglass'" class="service_logo" src="@/assets/spyglass2_color.svg"
                     alt="Service Logo">
                <img v-if="item==='speedydelivery'" class="service_logo" src="@/assets/speedydelivery_logo.svg"
                     alt="Service Logo">
                  <img v-if="item==='vortex'" src="@/assets/vortex.svg" class="vortex_logo"
                       alt="Vortex Logo">
                <!--              {{ pretty_names['services'][item] }}-->
              </div>
            </div>
          </div>

        </div>
      </div>
      <div v-else class="screen_block font_raleway font_weight_800
      bg_whitesmoke b_rad_10px padding_tb_20  margin_t_30 text_align_center">
        You are not subscribed to any services.
      </div>

      <Suspense>
        <template #default>
          <people></people>
        </template>
        <template #fallback>
          <h2>There are no people associated to your agency.</h2>
        </template>
      </Suspense>


<!--      <div v-if="we_have_people && show_people">-->
<!--        <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_whitesmoke flex_centered_column">-->
<!--          <div class="flex_space_between_row wid_100 flex">-->
<!--            <div class="spacer"></div>-->
<!--            <h3 class="  ">People</h3>-->
<!--            <div class="flex_centered_column" @click="toggle_show_people">-->
<!--              <div v-if="!show_people" class="check_box shadow_inner cursor_ptr"></div>-->
<!--              <div v-if="show_people">-->
<!--                <img class="check_box_icon cursor_ptr"-->
<!--                     src="@/assets/check-mark.svg"-->
<!--                     alt="check box image">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex_centered_row wide_button" v-for="item in my_people" :key="item.message">-->
<!--            <div class="context_item clickable_context_item cursor_ptr margin_t_15"-->
<!--                 @click="get_new_route('people', item)">{{ translate_tmh_to_pname_local(item) }}-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--      <div v-if="we_have_people && !show_people">-->
<!--        <div class="margin_auto margin_tb_10 screen_block padding_b_20 padding_t_10 bg_whitesmoke flex_centered_column">-->
<!--          <div class="flex_space_between_row wid_100 flex">-->
<!--            <div class="spacer"></div>-->
<!--            <h3 class="  ">People</h3>-->
<!--            <div class="flex_centered_column" @click="toggle_show_people">-->
<!--              <div v-if="!show_people" class="check_box shadow_inner cursor_ptr"></div>-->
<!--              <div v-if="show_people">-->
<!--                <img class="check_box_icon cursor_ptr"-->
<!--                     src="@/assets/check-mark.svg"-->
<!--                     alt="check box image">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--      <div v-if="!we_have_people" @click="toggle_hamburger_menu" class="screen_block font_raleway font_weight_800 bg_whitesmoke b_rad_10px-->
<!--      padding_tb_20  margin_t_30 margin_b_20 text_align_center bb padding_lr_15">-->
<!--        There are no other people associated with your organization. You have to 'Meet' other people first.-->
<!--      </div>-->

    </div>


    <basic_agency_info_box
        class="margin_t_30 text_align_center"
        :owners_perm="owners_perm"
        v-if="profile_agency_name"
        :agency_name="profile_agency_name"
        :full_street_address="full_street_address"
        :phone_number="profile_agency_phone_number"
        :email="profile_agency_email"
        :city_state_zip="profile_city_state_zip"
    ></basic_agency_info_box>

    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>

  </section>
  <outside_footer
      class="margin_t_25 footer_c"
      @link_clicked="hamburger_menu_false()"
  >
    >
  </outside_footer>

</template>


<script>
/* eslint-disable */
import {
  add_class_to_body,
  desktop_size,
  get_new_url,
  get_associated,
  colors,
  parse_internal_json,
  get_profile_route,
  prime_socket,
  receive_over_socket,
  be_main_address,
  pda_admin_ui_perm_available,
  validate_phonenumber,
  validate_email,
  get_rank_from_cache,
  get_date_string,
  good_token_now,
  get_logo_image_from_server,
  translate_tmh_to_pname,
  analytic,
  close_spinner_slow_connection,
  open_spinner,
  close_spinner,
  create_tmh_user_to_pname_map,
  sorted_usernames_last_first, sortObjectByKeys, sortObjectByValuesToArray,
} from "@/library";
import store from "@/store";
import {mapGetters} from "vuex";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_text_button from "@/components/parts/basic_text_button";
import dual_select_and_launch from "@/components/parts/dual_select_and_launch";
import error_popup_queue from "@/components/basics/error_popup_queue";
import image_upload_widgit from "@/components/parts/image_upload_widgit";
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import label_value_non_editable from "@/components/parts/label_value_non_editable";
import label_with_input_text from "@/components/parts/label_with_input_text";
import launch_button2 from "@/components/parts/launch_button2";
import notification_popup from "@/components/basics/notification_popup";
import basic_agency_info_box from "@/components/parts/basic_agency_info_box";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import {Database} from "@/client_db";
import axios from "axios";
import spinner from "@/components/parts/spinner.vue";
import network_status_box from "@/components/parts/network_status_box.vue";
import people from "@/components/orgs/pda/people.vue";

export default {
  name: "pda_profile_main",
  async beforeMount() {
    await this.get_my_rank()
    await prime_socket(this.local_message_handler)
    this.agency_interface = await pda_admin_ui_perm_available()
    await this.local_page_update()
  },
  components: {
    network_status_box,
    spinner,
    basic_icon_button,
    basic_slide_over_menu,
    basic_text_button,
    dual_select_and_launch,
    error_popup_queue,
    image_upload_widgit,
    label_value_non_editable,
    label_with_input_text,
    launch_button2,
    // invisible_backer_general,
    notification_popup,
    outside_footer,
    inside_banner,
    universal_pop_up_slot,
    universal_invisible_backer,
    basic_agency_info_box,
    people
  },

  // ,
  data() {
    return {
      min_core_height: this.content_less_footer_height,
      colors: colors,
      owners_perm_: false,
      admins_perm_: false,
      mobile_mode_x: true,
      show_people_: false,
      hamburger_menu_up_x: false,
      hamburger_left_menu_up_x: false,
      profile_form_up_x: false,
      associate_form_up_x: false,
      promote_form_up_x: false,
      assoc_code_invitation_: {},
      admin_gear_available_: false,
      opaque_slide_menu_x: false,
      assoc_acceptance_code: "",
      promotees_listing: "",
      logo_image_: "",
      agency_interface: false,
      agency_profile_text: {
        agency_name: "",
        street_address: "",
        street_address2: "",
        city: "",
        state: "",
        zip: "",
        phone_number: "",
        email: ""
      },


      my_orgs_: [],
      pretty_names: {
        'orgs': {},
        'services': {}
      },
      my_services_: [],
      // my_people_: [],
      my_rank_: "..."
    }
  },


  watch: {
    async update_page() {
      // console.log("update_page watcher is triggered")
      await this.local_page_update()
    },
    // async indb_has_changed() {
    //   await this.local_page_update()
    // },
    // async current_preamble_changed() {
    //   await this.local_page_update()
    // },
    window_width(new_w) {
      // console.log("Inside window_width launcher...")
      this.run_window_width_adjustments(new_w)
    },
    new_logo_available() {
      console.log("Inside new_logo_available watcher")
      store.state.new_logo_available = false
      this.upload_logo_image()
    },

  },
  computed: {
    ...mapGetters([
      'get_agency_logos',
      'window_width',
    ]),
    // we_have_people() {
    //   return this.my_people_.length > 0;
    // },
    // show_people() {
    //   return this.show_people_
    // },
    profile_content_div_height() {
      try {
        return document.getElementById(this.content_wrapper_id).clientHeight
      } catch (error) {
        return null
      }

    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    content_less_footer_height() {
      // let content_no_footer = document.getElementById("profile_content")
      let h = this.profile_content_div_height
      if (h) {
        return h
      } else {
        return 700
      }

    },
    my_rank() {
      return this.my_rank_
    },
    owners_perm() {
      console.log("in owner perm")
      console.log(this.owners_perm_)
      return this.owners_perm_
    },

    admins_perm() {
      // console.log("in owner perm")
      // console.log(this.owners_perm_)
      return this.admins_perm_
    },
    promotees_available() {
      return Object.keys(this.promotees_listing).length > 0;
    },
    profile_city_state_zip() {
      let city = this.profile_agency_city
      let state = this.profile_agency_state
      let zip = this.profile_agency_zip
      if (!(city && state && zip)) {
        return ""
      } else {
        return `${city}, ${state} ${zip}`
      }
    },
    profile_agency_name() {
      try {
        return this.agency_profile_text.agency_name
      } catch (error) {
        return ""
      }
    },
    profile_agency_address() {
      try {
        return this.agency_profile_text.street_address
      } catch (error) {
        return ""
      }
    },
    profile_agency_address2() {
      try {
        return this.agency_profile_text.street_address2
      } catch (error) {
        return ""
      }
    },
    profile_agency_city() {
      try {
        return this.agency_profile_text.city
      } catch (error) {
        return ""
      }
    },
    profile_agency_state() {
      try {
        return this.agency_profile_text.state
      } catch (error) {
        return ""
      }
    },
    profile_agency_zip() {
      try {
        return this.agency_profile_text.zip
      } catch (error) {
        return ""
      }
    },
    profile_agency_email() {
      try {
        return this.agency_profile_text.email
      } catch (error) {
        return ""
      }
    },
    full_street_address() {
      let one = this.agency_profile_text.street_address
      let two = this.agency_profile_text.street_address2
      if (one === "") {
        return ""
      } else if (two === "") {
        return one
      } else {
        return one + ", " + two
      }
    },
    profile_agency_phone_number() {
      try {
        return this.agency_profile_text.phone_number
      } catch (error) {
        return ""
      }

    },
    new_logo_available() {
      return store.state.new_logo_available
    },
    opaque_slide_menu() {
      // console.log(`Retuning opaque slide menu state: ${this.opaque_slide_menu_x}`)
      return this.opaque_slide_menu_x
    },
    logo_image() {
      // console.log("inside the profile-image computed prop bc of image chang.")
      return this.logo_image_
    },
    admin_gear_available() {
      return this.admin_gear_available_
    },
    acceptance_code_entered() {
      return this.assoc_acceptance_code
    },
    current_assoc_code_invitation() {
      // console.log("in current_assoc_code_invitation computed")
      let codes = this.assoc_code_invitation_
      // console.log("the codes available are...")
      // console.log(codes)
      let current
      let latest = 0
      //Get the latest timestamp
      for (const code in codes) {
        console.log(`Iterating codes: ${code}`)
        if (codes[code] > latest) {
          latest = codes[code]
          current = code
        }
      }
      //  if latest code is no more than 2 minutes old, return it, else ""
      if ((Date.now() - latest) <= (1 * 6)) {
        // console.log(`Looks like I found a good code with ${current}`)
        return current.toString()
      } else {
        // console.log("no good code found.")
        return ""
      }

    },
    hamburger_menu_up() {
      return this.hamburger_menu_up_x
    },
    hamburger_left_menu_up() {
      return this.hamburger_left_menu_up_x
    },
    profile_form_up() {
      return this.profile_form_up_x
    },
    associate_form_up() {
      return this.associate_form_up_x
    },
    promote_form_up() {
      return this.promote_form_up_x
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    update_page() {
      // console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
    indb_has_changed() {
      return store.getters.freetime_dropbox_indb_update_status
    },
    current_preamble_changed() {
      return store.getters.find_current_preamble
    },
    my_orgs() {
      return this.my_orgs_
    },
    my_services() {
      return this.my_services_
    },
    // my_people() {
    //   return this.my_people_
    // },
    org_context() {
      return store.getters.org_context
    },
  },
  methods: {

    tester() {
      console.log("got an event that the save image button was clicked.")
      this.$refs.camera_widgit.toggle_controls_active();
    },

    toggle_show_people() {

      this.show_people_ = !this.show_people_

    },

    tester_tools() {
      console.log("got an event that tester tools was clicked.")
      // this.$refs.camera_widgit.toggle_controls_active();
      // open_new_tab("https://google.com")
    },
    close_spinner_wrapper() {
      close_spinner_slow_connection()
    },

    do_nothing() {
      //pass
    },

    translate_tmh_to_pname_local(tmh_name) {
      return translate_tmh_to_pname(tmh_name)
    },

    async get_assistant() {
      // analytic("01:01:16:908")
      store.commit('set_spinner_state', true)
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/generate_hours_report'
      let target_url = be_main_address('ft') + 'get_assistant'
      open_spinner()
      axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            if (response.data['error'] === true) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'tmh_assistant' + '.exe');
              document.body.appendChild(fileLink);

              fileLink.click();
              // store.commit('set_spinner_state', false)
            }
            close_spinner()
            store.commit('notification_enqueue', "The Tiny Magic Hat Assistant has been downloaded.")
          });
      // store.commit('set_spinner_state', false)
    },

    async go_to_dev_page() {
      this.hamburger_menu_false()
      store.commit('update_context_changing_route', false)
      await this.$router.push({path: `/hello_landing`})
    },
     async go_to_assistant_registration_page() {
      this.hamburger_menu_false()
      store.commit('update_context_changing_route', false)
      let r = `/pda/${this.org_context}/freetime/assistant_registration`
      await this.$router.push({path: r})
    },

    async get_my_rank() {
      let name_of_user
      if (this.users_name === null || this.users_name === undefined) {
        name_of_user = store.getters.my_name
      } else {
        name_of_user = this.users_name
      }
      let users_rank = get_rank_from_cache(name_of_user, this.org_context)

      if (users_rank) {
        this.my_rank_ = users_rank
        return null
      }

      let data1 = {
        "caller": store.getters.my_name,
        "subscriber": this.org_context,
        "id_token": store.getters.my_id_token,
        "refresh_token": store.getters.my_refresh_token,
      }


      let target = be_main_address('pda_mother') + 'get_my_rank'
      // let endpoint = 'http://192.168.1.10:8087/ft/upload_match_template'

      await axios({
        method: 'post',
        url: target,
        data: data1,
      }).then((response) => {
        // console.log("get my rank response.")
        // console.log(response.data)
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          // let message = parse_internal_json(response.data)['payload']['content']['message']
          let message = response.data.message
          store.commit('error_enqueue', message)
        } else {
          // console.log("No error on rank check...")
          // console.log(`Rank is be...${response.data.data}`)
          let rank = response.data.data
          this.my_rank_ = rank
          store.commit('update_rank_cache', [name_of_user, this.org_context, rank])
        }

      }, (error) => {
        console.log('Error on logo upload.')
        console.log(error);
      });
    },

    async check_for_general_admin_ui_perm() {
      // console.log("inside get profile image from database.")
      let search = {
        key: 'desc',
        additional_context: [['div', 'widgits']],
        base_context: store.getters.find_current_preamble
      }
      let profile_data = await this.db.db_search(search)
      // console.log(profile_data)
      let target = "general pda administrator front end permission"
      if (profile_data.includes(target)) {
        this.admins_perm_ = true
        console.log("You have the general admins perm")
        return true
      } else {
        this.admins_perm_ = false
        console.log("You don't have the general admins perm")
        return false
      }
    },


    async check_for_owner_ui_perm() {
      console.log("inside looking for owner permission.")
      let search = {
        key: 'desc',
        additional_context: [['div', 'widgits']],
        base_context: store.getters.find_current_preamble
      }
      // let you_have_it = false
      let profile_data = await this.db.db_search(search)

      let target = "profile editing widgit"
      if (profile_data.includes(target)) {
        this.owners_perm_ = true
        console.log("You have the owners ui perm")
        return true
      } else {
        this.owners_perm_ = false
        console.log("You don't have the owners ui perm")
        return false
      }


      // // console.log(profile_data)
      // if (profile_data[0] === "profile editing widgit") {
      //   // console.log("You have the owner permission under section 0")
      //   you_have_it = true
      // } else if (profile_data[1] === "profile editing widgit") {
      //   console.log("You have the owner permission under section 1")
      //   you_have_it = true
      // } else {
      //   console.log("You DON'T have the owner permission at all.")
      // }
      //
      // if (you_have_it) {
      //   console.log("You still have owner perm")
      //   this.owners_perm_ = true
      // }


      // return profile_data[0]
    },


    async get_profile_text_from_db() {
      // console.log("inside get profile image from database.")
      let search = {
        key: 'public_profile_data',
        additional_context: [['div', 'profile'], ['div', 'data']],
        base_context: store.getters.find_current_preamble
      }
      let profile_data = await this.db.db_search(search)
      return profile_data[0]
    },


    async catch_agency_profile_data() {

      let valid_email = validate_email(this.$refs.input_agency_email.text_value_)
      if (!valid_email) {
        store.commit("error_enqueue", "The agency email address you entered is invalid.")
        this.$refs.input_agency_email.text_value_ = ""
        this.agency_profile_text.email = ""
        this.toggle_profile_form()
        return null
      }
      let valid_phone_number = validate_phonenumber(this.$refs.input_agency_phone.text_value_)
      if (!valid_phone_number) {
        store.commit("error_enqueue", "The agency phone number you entered is invalid.")
        this.$refs.input_agency_phone.text_value_ = ""
        this.agency_profile_text.phone_number = ""
        this.toggle_profile_form()
        return null
      }


      this.agency_profile_text = {
        "agency_name": this.$refs.input_agency_name.text_value_,
        "street_address": this.$refs.input_agency_address1.text_value_,
        "street_address2": this.$refs.input_agency_address2.text_value_,
        "city": this.$refs.input_agency_city.text_value_,
        "state": this.$refs.input_agency_state.text_value_,
        "zip": this.$refs.input_agency_zip.text_value_,
        "phone_number": this.$refs.input_agency_phone.text_value_,
        "email": this.$refs.input_agency_email.text_value_,
      }
      // console.log(JSON.stringify(this.profile_text))
      this.toggle_profile_form()
      await this.save_profile_info()
    },
    async save_profile_info() {
      let data1 = {
        caller: store.getters.my_name,
        subscriber: this.org_context,
        profile_text: this.agency_profile_text,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url = be_main_address('pda_profiles') + 'save_agency_profile'
      axios.post(target_url, data1)
          .then((response) => {
            let the_data_only = parse_internal_json(response.data)

            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } catch (error) {
              store.commit('notification_enqueue', the_data_only.message)
            }
            // this.local_page_update()

          });
    },
    async personal_gear_eligible() {
      // return this.agency_interface_perm_true
      // console.log("STARTING Profile editing button ELIGIBLE...")
      let search = {
        key: 'widgit',
        additional_context: [['div', 'widgits']],
        // additional_context: [],
        base_context: store.getters.find_current_preamble
      }
      let route_perms_array = await this.db.db_search(search)

      for (let k of route_perms_array) {
        if (k === "personal_profile_editing") {
          // console.log("Route permission has been discovered.")
          return true
        } else {
          // console.log("looking for pda admin but found:")
          // console.log(k)
        }
      }
      // console.log("returning false from checking on the personal profile editing widgit.")
      return false
    },
    // async get_logo_image_from_server(uuid) {
    //
    //   // console.log("You just clicked get_profile_image_from_server.")
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     uuid: uuid,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token
    //   }
    //   let target_url = be_main_address('pda_profile') + 'get_agency_logo'
    //   return axios.post(target_url, data1)
    //
    // },

    async get_logo_image_from_db() {
      let search = {
        key: 'logo_image',
        additional_context: [['div', 'profile'], ['div', 'images']],
        base_context: store.getters.find_current_preamble
      }

      let db_target_item = await this.db.db_search(search)
      let image_value = db_target_item[0]

      // console.log(`image value from IndexedDB is ${image_value}`)

      if (image_value === 'on server') {
        let uuid_search = {
          key: 'uuid',
          additional_context: [['div', 'profile'], ['div', 'images']],
          base_context: store.getters.find_current_preamble
        }

        let db_item = await this.db.db_search(uuid_search)
        let uuid = db_item[0]

        console.log(`Should have a uuid: ${uuid}`)
        this.profile_image_ = get_logo_image_from_server(uuid)
            .then(response => {
              let the_data_only = parse_internal_json(response.data)
              let img = the_data_only['data']
              this.logo_image_ = img

              let new_key = []

              let search_key_components = ['base_context', 'additional_context']
              for (const component of search_key_components) {
                for (const element of uuid_search[component]) {
                  for (const item of element) {
                    new_key.push(item)
                  }
                  // console.log(`New key is currently ${JSON.stringify(new_key)}`)
                }
              }
              let new_key_string = '/'
              for (const word of new_key) {
                new_key_string = new_key_string + word + '/'
              }
              new_key_string = new_key_string + uuid
              console.log(new_key_string)

              let db_core_value = {
                "main_key": "logo_image",
                "logo_image": img,
                "type": "image",
                "uuid": uuid,
              }
              let db_whole_value = {
                timestamp: get_date_string(null, 5),
                value: db_core_value
              }
              return this.db.addKeyValue(new_key_string, db_whole_value)
            })
            .then((dexie_res) => {
              console.log("Image has been added to database.")
              console.log(`key used by dexie for save wase: ${dexie_res}`)
            })
      } else {
        return image_value
      }


    },

    async upload_logo_image() {
      console.log("inside upload image")
      console.log(store.getters.get_agency_logos[store.getters.org_context])
      let data1 = {
        "caller": store.getters.my_name,
        "subscriber": store.getters.org_context,
        "id_token": store.getters.my_id_token,
        "refresh_token": store.getters.my_refresh_token,
        "pda_logo_image": store.getters.get_agency_logos[store.getters.org_context],
      }


      let target = be_main_address('pda_profiles') + 'save_agency_logo'

      await axios({
        method: 'post',
        url: target,
        data: data1,
      }).then((response) => {
        if (response.data['error'] === true) {
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        } else {
          let the_data_only = parse_internal_json(response.data)
          store.commit('notification_enqueue', the_data_only.message)
        }

      }, (error) => {
        console.log('Error on logo upload.')
        console.log(error);
      });
    },

    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)

      // console.log("in local message handler")
      // console.log(`update is ${update}`)

      if (update === true) {
        // console.log("SOCKET MESSAGE RECEIVED AND WITH UPDATE OF TRUE")
        await this.local_page_update()
        return null
      } else if (update === false) {
        return null
      }

      if (update) {
        try {
          let direct_command = update['message']
          let value = update['data']
          if (direct_command === "update_your_rank") {
            // console.log("update you rank direct command triggered")
            this.my_rank_ = value
          } else if (direct_command === "update_other_rank") {
            // console.log("update you rank direct command triggered")
            let values_list = JSON.parse(value)
            let promotee = values_list[0]
            let org = values_list[1]
            let rank = values_list[2]
            store.commit('update_rank_cache', [promotee, org, rank])
          } else {
            console.log("unhandled command triggered. No action.")
            return null
          }
        } catch (error) {
          console.log("Error on update.  Just refresh the page.")
          await this.local_page_update()
        }
      } else {
        // console.log("no update")
      }
    },

    async get_promotees_listing() {
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      if (data1.subscriber === "") {
        //this code gets runs from the updater when leaving the page, so here we skip running it to avoid the error.
        // console.log("No subscriber.  No check.")
        return null
      }

      let endpoint = "get_potential_promotees"
      let target_url = be_main_address("pda_mother") + endpoint


      axios.post(target_url, data1)
          .then((response) => {
            let the_data_only = parse_internal_json(response.data)
            if (the_data_only.error === true) {
              console.log(the_data_only.payload.content.message)
            } else {
              // let sorted_array = the_data_only['data'].sort()
              // console.log('Promotees listing....')
              // console.log(typeof the_data_only['data'])
              // console.log(the_data_only['data'])
              // console.log(the_data_only['data'].sort())
              // console.log(sortObjectByValuesToArray(the_data_only['data']))

              this.promotees_listing = sortObjectByValuesToArray(the_data_only['data'])
            }

          });
    },
    promote_associate(name, rank) {
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        rank: rank,
        promotee: name,
      }

      if (data1.subscriber === "") {
        //this code gets runs from the updater when leaving the page, so here we skip running it to avoid the error.
        // console.log("No subscriber.  No check.")
        return null
      }

      let endpoint = "promote_user"
      let target_url = be_main_address("pda_mother") + endpoint


      axios.post(target_url, data1)
          .then((response) => {
            this.toggle_promote_form()
            if (response.data.error === true) {
              store.commit('error_enqueue', response.data.message)
            } else {
              console.log("Error was not detected.")
              store.commit('notification_enqueue', response.data.message)
              store.commit('update_rank_cache', [name, store.getters.org_context, rank])
            }

          });
    },
    accept_invitation() {
      console.log("Attemptintg to print the acceptance code")
      this.$refs.accepted_code.emit_state()
      console.log(this.acceptance_code_entered)
      let data1 = {
        caller: store.getters.my_name,
        code: this.acceptance_code_entered,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      let target_url = be_main_address('assocs') + 'accept_association'

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Association Code Acceptance response from server is...")
            console.log(typeof response)
            console.log(response)
            let the_data_only = parse_internal_json(response.data)
            // console.log("parsed the_data_only from server is...")
            // console.log(the_data_only)
            if (the_data_only.payload.content.error === true) {
              console.log("inside accept assoc call on error")
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } else {
              let new_code = parse_internal_json(response.data)['code']
              let now = Date.now()
              this.assoc_code_invitation_[new_code] = now
            }

          });
    },

    catch_accepted_code(code) {
      this.assoc_acceptance_code = code
    },
    async go_to_profile() {
      this.hamburger_menu_false()
      await this.$router.push({path: `${get_profile_route()}`})
    },
    async profile_button_clicked() {
      // console.log("inside profile_button_clicked")
      try {
        this.toggle_hamburger_menu()
        await this.$router.push({path: `${get_profile_route()}`})

      } catch (error) {
        console.log(error)
      }
    },
    async login_button_clicked() {
      // console.log("inside login_button_clicked")
      try {
        this.toggle_hamburger_left_menu()
        await this.$router.push({path: `/login`})

      } catch (error) {
        console.log(error)
      }
    },


    async generate_assoc_code() {
      console.log("INSIDE GENERATE ASSOC CODE")
      let m = ""
      if (!store.getters.org_context) {
        if (!good_token_now()) {
          m = "You do not have a good token. You need to log in again."
        } else {
          m = "There is a problem with your credentials. You will need to log in again to create an invitation."
        }
        store.commit('error_enqueue', m)
      }

      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url = be_main_address('pda_mother') + 'generate_assoc_code'

      axios.post(target_url, data1)
          .then((response) => {
            // console.log("Association Code response from server is...")
            // console.log(typeof response)
            // console.log(response)
            let code = response.data.code
            let the_data_only = parse_internal_json(response.data)
            // console.log("parsed code from server is...")
            // console.log(code)
            // console.log(response)
            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
              // console.log("inside generate assoc code call... there was an error.")
            } catch (error) {
              // let new_code = the_data_only.code
              let new_code = code
              let now = Date.now()
              this.assoc_code_invitation_[new_code] = now
            }

          });
    },

    async local_page_update() {

      console.log('LOCAL_PAGE_UPDATE_FUNCTION_IS_RUNNING.')
      try {
        this.logo_image_ = await this.get_logo_image_from_db()
        this.agency_profile_text = await this.get_profile_text_from_db()
        store.commit('set_agency_profile_text', this.agency_profile_text)
        store.commit('set_agency_profile_logo', this.logo_image_)
        this.admin_gear_available_ = await this.personal_gear_eligible()

        this.agency_interface = await pda_admin_ui_perm_available()
        // this.my_orgs_ = await get_associated(this.db, 'org')
        // console.log(`my orgs is ${JSON.stringify(this.my_orgs_)}`)
        this.my_services_ = await get_associated(this.db, 'service')

        //Non-admins should not have an agency level speedydelivery interface. So, eliminate it for non-admins.
        if (!this.admins_perm) {
          this.my_services_ = this.my_services_.filter(e => e !== 'speedydelivery');
        }

        // console.log(`MY PEOPLE******************************************`)
        // this.my_people_ = await sorted_usernames_last_first(this.db)
        // console.log(this.my_people_)

        for (const service of this.my_services_) {
          // console.log("Looking for service pretty names.")
          // console.log(this.my_services_)
          let p_name = await this.get_pretty_name(service, 'service')
          // console.log(`The pretty name for ${service} was ${p_name}.`)
          this.pretty_names['services'][service] = p_name
        }
        //  Get potential promotees listing

        if (this.agency_interface) {
          console.log("Agency interface is available to this user.")
          await this.get_promotees_listing()
          // localStorage.setItem('agency_interface', 'TRUE')
        } else {
          console.log("Agency interface not available to this user.")
          // localStorage.setItem('agency_interface', 'FALSE')
        }

      } catch (error) {
        // console.log("Local page update could not access the database.")
      }


    },
    toggle_hamburger_menu() {
      // console.log(`In toggle hamburger menu. Before toggle Hamburger menu is ${this.hamburger_menu_up}.`)
      this.hamburger_menu_up_x = !this.hamburger_menu_up_x
      // console.log(`After toggle Hamburger menu is ${this.hamburger_menu_up}.`)
    },
    hamburger_menu_false() {
      // console.log(`In toggle hamburger menu. Before toggle Hamburger menu is ${this.hamburger_menu_up}.`)
      this.hamburger_menu_up_x = false
      // console.log(`After toggle Hamburger menu is ${this.hamburger_menu_up}.`)
    },
    // toggle_hamburger_left_menu() {
    //   // consele.log('in toggle_hamburger_left_menu')
    //   this.hamburger_left_menu_up_x = !this.hamburger_left_menu_up_x
    // },
    toggle_profile_form() {
      this.profile_form_up_x = !this.profile_form_up_x
    },
    toggle_associate_form() {
      this.associate_form_up_x = !this.associate_form_up_x
    },
    toggle_promote_form() {
      this.promote_form_up_x = !this.promote_form_up_x
    },

    profile_up_hamburger_down() {
      this.profile_form_up_x = true
      this.hamburger_menu_up_x = false
    },
    associate_up_hamburger_down() {
      this.associate_form_up_x = true
      this.hamburger_menu_up_x = false
    },
    promote_up_hamburger_down() {
      this.promote_form_up_x = true
      this.hamburger_menu_up_x = false
    },

    async get_pretty_name(reg_name, ent_type) {
      // console.log("")
      // console.log("Inside get pretty name")
      // console.log(`reg name is ${reg_name}`)
      // console.log(`ent type is ${ent_type}`)

      let current_preamble_clone = store.getters.find_current_preamble
      // console.log("in get_pretty_name")
      // console.log(current_preamble_clone)
      let search = {
        'key': 'pretty_name',
        'base_context': current_preamble_clone,
        'additional_context': [[ent_type, reg_name], ['div', 'misc']]
      }
      let pretty_names_array = await this.db.db_search(search)
      // console.log(pretty_names_array)
      if (pretty_names_array.length > 1) {
        // console.log(`There were multiple pretty names returned for ${name}. This should not happen.`)
        // console.log(pretty_names_array)
        return reg_name
      } else if (pretty_names_array.length === 0) {
        //No pretty name was found for this item. Send back original name.
        return reg_name
      } else {
        //Exactly one pretty name was found for this item.  Return it.
        return pretty_names_array[0]
      }
    },
    run_window_width_adjustments(new_w) {
      if (new_w === undefined) {
        return null
      }
      // console.log(`in run_window_width_adjustments with width of ${new_w}.`)
      // if (new_w >= 280) {
      //   this.mobile_mode_x = true
      // }
      //
      // remove_class_to_body('content')
      // remove_class_to_body('content_large_screen')
      // add_class_to_body('content_no_radial')

      if (new_w >= 500) {
        // console.log("Activating transparent slide menu")
        this.opaque_slide_menu_x = false
        // console.log("Setting opaque menu to false")
      } else {
        this.opaque_slide_menu_x = true
        // console.log("Setting opaque menu to true")
      }

      // if (new_w < 450) {
      // remove_class_to_body('content_no_radial')
      // remove_class_to_body('content_large_screen')
      // add_class_to_body('content')
      // }
      // if (new_w >= 450) {

      // remove_class_to_body('content_large_screen')
      // add_class_to_body('content_no_radial')
      // this.mobile_mode_x = true
      // }
      // if (new_w >= 375) {
      //   // message = 'The window width >= 375'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 540) {
      //   // message = 'The window width >= 540'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 640) {
      //   // message = 'The window width >= 640'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1024) {
      //   // message = 'The window width >= 1024'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1200) {
      //   // message = 'The window width >= 1200'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w <= desktop_size) {
        // remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        this.mobile_mode_x = true
        // const matches = document.querySelectorAll(".screen_block_no_shadows");
        // matches.forEach(function (match) {
        //   match.classList.remove('screen_block_no_shadows');
        //   match.classList.add('screen_block');
        // });
      } else {
        // add_class_to_body('content_no_radial')
        // remove_class_to_body('content_no_radial')
        this.mobile_mode_x = false
        // const matches = document.querySelectorAll(".screen_block");
        // matches.forEach(function (match) {
        //   match.classList.remove('screen_block');
        //   match.classList.add('screen_block_no_shadows');
        // });
      }
      // if (new_w >= desktop_size) {
      //   remove_class_to_body('content_no_radial')
      //   add_class_to_body('content_large_screen')
      //   this.mobile_mode_x = false

      // const matches = document.querySelectorAll(".screen_block");
      // matches.forEach(function (match) {
      //   match.classList.remove('screen_block');
      //   match.classList.add('screen_block_no_shadows');
      // });

      // }
    },
    async get_new_route(envelope, context) {
      // console.log("inside get_new_route function")
      const preamble = [envelope, context]
      // console.log("envelope and context are:")
      // console.log(envelope)
      // console.log(context)
      await store.dispatch('stack_proposed', preamble)
      let new_url = await get_new_url(this.db)
      // console.log("Returned new url is...")
      // console.log(new_url)
      this.hamburger_menu_false()
      await this.$router.push({path: new_url})
    },
    profile_route(envelope, context) {
      const preamble = [envelope, context]
      store.dispatch('stack_proposed', preamble)
      let new_url = `/${context}/profile`
      this.$router.push({path: new_url})
    },

  },
  async mounted() {
    // console.log("Running mounted")
    this.db = new Database();

    await this.check_for_owner_ui_perm()
    await this.check_for_general_admin_ui_perm()

    // let the_map = store.getters.get_pname_to_tmhname_map
    // the_map = null

    // this.my_people_ = await sorted_usernames_last_first(this.db)
    // console.log("***********************************Here are my sorted people....")
    // console.log(this.my_people_)
    // localStorage.setItem('my_people', JSON.stringify(this.my_people_))

    // if (!the_map) {
    //   let the_map = await create_tmh_user_to_pname_map(this.db)
    //   console.log("MAP is....")
    //   console.log(the_map)
    // }

    //

    await prime_socket(this.local_message_handler)
    await this.local_page_update()


    this.run_window_width_adjustments(this.window_width)
    // add_class_to_body('content_no_radial')
    // add_class_to_body('content')
    await store.dispatch('notification_restart')
  },

}
</script>

<style scoped>

/*@import '../../common.css';*/

#still_image {
  width: 120px;
  height: 140px;
}

h3 {
  color: var(--wyatt_gray);
}


/*.context_item {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: center;*/
/*  !*background-color: var(--primary-purple-mid-light);*!*/
/*  border-radius: 10px;*/
/*  width: 85%;*/
/*  !*margin: 10px;*!*/
/*  padding: 10px;*/
/*}*/

.wide_button {
  width: 85%;
}

.spacer {
  position: relative;
  top: 30px;
  width: 60px;
}

.promo_selector {
  max-width: 300px;
}


.top_menu_icon_buttons {
  width: 100%;
}


.agency_info_popup_sizing {
  width: 65%;
  margin: auto;
}

.promotes_popup_sizing {
  width: 75%;
  margin: auto;
}

@media (min-width: 100px) {
  .top_menu_icon_buttons {
    width: 100%;
    max-width: 500px;
  }

  .agency_info_size {
    height: 500px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
    margin-right: 20px;

  }

  .check_box_icon {
    height: 30px;
    margin-right: 20px;
  }


  .assoc_popup_main_box {
    width: 225px;
    margin: auto;
  }

  .assoc_popup_content_column {
    width: 80%;
  }


}


@media (min-width: 100px) {

  .assoc_popup_main_box {
    width: 225px;
    margin: auto;
  }

  .services_box {
    max-width: 500px;
    width: 98vw;
    padding-bottom: 20px;
  }


  .service_cards {
    max-width: 520px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
  }

  .service_card {
    background-color: white;
    width: 150px;
    min-height: 116px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 132px;
  }

  .service_card:hover {
    background-color: var(--eli_gray);
    transition: all 0.4s;
  }

  /*.add_agency {*/
  /*  margin-bottom: 50px;*/
  /*}*/
  .service_name {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    margin-top: 10px;
  }

  .service_logo {
    width: 100px;
  }

   .vortex_logo {
    width: 70px;
  }

  .tmh_agency_logo {
    width: 100px;
  }

  .material_icon {
    font-size: 60px;
    color: var(--primary-purple);
  }

}

@media (min-width: 500px) {

  .assoc_popup_main_box {
    width: 275px;
    margin: auto;
  }

}


/*@media (min-width: 700px) {*/

/*.pda_profile {*/
/*  width: 80vw;*/
/*  margin: auto;*/
/*}*/

/*.agency_info_size {*/
/*  height: 800px;*/
/*}*/
/*}*/

@media (min-width: 1000px) {

  .assoc_popup_main_box {
    width: 400px;
    margin: auto;
  }

  .services_box {
    padding-bottom: 20px;
    width: 98vw;
    max-width: 500px;
  }

}

@media (min-width: 1200px) {

   .vortex_logo {
    width: 120px;
  }


  .services_box {
    max-width: 640px;
    width: 98vw;
  }

  .service_cards {
    max-width: 520px;
    padding-bottom: 50px;
  }

  .service_card {
    background-color: white;
    width: 240px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    padding-top: 0;
    padding-bottom: 10px;
    height: 232px;
  }

  .service_card:hover {
    background-color: var(--eli_gray);
    transition: all 0.4s;
  }

  /*.add_agency {*/
  /*  margin-bottom: 50px;*/
  /*}*/
  .service_name {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .service_logo {
    width: 200px;
    /*padding-top: 50px;*/
  }

  .tmh_agency_logo {
    width: 200px;
    /*padding-top: 50px;*/
  }

  .material_icon {
    font-size: 80px;
    color: var(--primary-purple);
  }

}
</style>